import React, { useEffect } from 'react';
import Header from '../../components/commons/Header';
import Foot from '../../components/commons/Foot';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PATH_HOME, PATH_MY_PRODUCTS } from '../../utils/path';
import { Alert, Button, CardGroup, Col, Container, Row } from 'react-bootstrap';
import { productGetAllDataById } from '../../actions/productAction';
import ProductResourceCard from '../../components/product/ProductResourceCard';
import ProductResourceForm from '../../components/product/ProductResourceForm';
import { setShowModal } from '../../actions/generalAction';

const ProductResourcePage = () => {

    const { id } = useParams();
    const dispatch = useDispatch();

    const logged = useSelector(state => state.sesion.logged);
    const resourceList = useSelector(state => state.product.resourceList);

    const navigate = useNavigate();

    useEffect(() => {
        if (!logged) {
            navigate(PATH_HOME); // Here only with logged users
        }
    // eslint-disable-next-line
    }, [logged]);

    useEffect(() => {
        if (id) {
            dispatch(productGetAllDataById(id));
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (resourceList && resourceList.length>0) {
            //console.log("ProductResourcePage.useEffect[resourceList]: ", resourceList);
        }
        // eslint-disable-next-line
    }, [resourceList]);

    const openModal = () => {
        dispatch(setShowModal(true));
    }

return (<>
    <Header/>
    <br/>
    <Container>
        <Row>
            <Col>
                <h2>Imágenes</h2>
            </Col>
            <Col className="d-flex justify-content-end">
                <Button onClick={openModal}>Agregar</Button>
                <Button className='ml-0.5' as={NavLink} to={PATH_MY_PRODUCTS}>Regresar</Button>
            </Col>
        </Row>
        
        <br/><br/>
        {
            resourceList.length === 0 && 
            <Alert variant='info'>
                No se han agregado imagenes
            </Alert>
        }
        <CardGroup>
            {
                resourceList.map( (r, i) => (
                    <Col xl={3} lg={4} md={6} sm={12} key={i} 
                        //className='mb-4'
                        //style={{padding: '1rem'}}
                        className='masonry-col d-flex align-items-center justify-content-center'
                    >
                        <ProductResourceCard resource={r}/>
                    </Col>
                ))
            }
        </CardGroup>
    </Container>
    <ProductResourceForm />
    <Foot/>
</>);
}
 
export default ProductResourcePage;