export const PATH_HOME = "/";
export const PATH_LOGIN = "/login";
export const PATH_SIGNUP = "/signup"; // only email and password
export const PATH_PASSWORD = "/password"; // change password
export const PATH_USER = "/user"; // see and edit own data user

export const PATH_PRODUCT_CREATE = "/productcreate";
export const PATH_PRODUCT_UPDATE = "/productupdate";
export const PATH_PRODUCT_IMAGES = "/productimages"; // edit product images
export const PATH_PRODUCTS_SAVED = "/savedproducts" // list of saved products, favorites.
export const PATH_MY_PRODUCTS = "/myproducts" // list of registered products (real estates) .
export const PATH_PRODUCT = "/product"; // see a product

export const permissionCheck = (user, permission) => {
    //return true;
    //console.log("PV 1");
    if ( !user ) return false;
    //console.log("PV 2");
    if ( !user.role ) return false;
    //console.log("PV 3");
    if ( !user.role.roleid ) return false;
    //console.log("PV 4");

    let role = user.role.roleid;
    if ( isNaN(role) ) role = Number(role);
    //console.log("PV 5");
    // 1 User . currently all access
    if ( role === 1 ) return true;
    //console.log("PV 6");
    // 2 Manager
    if ( role === 2 ) return true
    //console.log("PV 7");

    
    return false; // default
    
}