import axios from "axios";
import { SET_SESSION, SET_USER_SESSION } from "../const/actionType";
// eslint-disable-next-line
import { getErrorMessage, setAuthToken, tieneError, checkAuthToken } from "../utils/functions";
// eslint-disable-next-line
import { envioCorreo } from "../utils/correo";
import Swal from 'sweetalert2';
import { jwtDecode } from "jwt-decode";
import store from "../store";

export const loginUser = (loginData) => async (dispatch) => {
    // dispatch is there in case I want to call another action or method
    // loginData must be src/dt/Login.java in the back, that is: {email, password, newPassword}
    
    console.log('sesionAction.loginUser DatosLogin:');
    console.log(loginData);

    return new Promise((resolve, reject) => {
        axios.post('/auth/login', loginData, {
            // If I want to get on xml format I must to set: 'Accept': 'application/xml'
            headers: {'Accept': 'application/json', 'Content-type': 'application/json'}
        }).then( response => {
            console.log('sesionAction.loginUser response:');
            console.log(response);
            //const { authorization, uuid: uuid } = response.headers;
            const { authorization, uuid } = response.data;
            localStorage.setItem("token", authorization );
            localStorage.setItem("uuid", uuid);
            
            // crear función para añadir token a axios, para no tener que poner en los headers en cada petición
            setAuthToken(authorization);
            checkAuthToken();
            // decoded tiene la estructura: {sub, exp} 
            // sub tiene el usuario que en este caso es el correo y exp tiene el la fecha en segundos en el que expira la sesión
            const decoded = jwtDecode(authorization);
            dispatch({
                type: SET_SESSION,
                payload: { userSession: decoded, logged: true, tryLogin: true }
            });
            //envioCorreo(decoded.sub, "Inicio de sesión", "Has iniciado sesión en Embudi");

            resolve(response);
        }).catch ( error => {
            console.log('sesionAction.loginUser error:');
            console.log(error);
            Swal.fire({icon: "error", title: "Error", text: "Usuario o contraseña incorrecto", toast: true});
            reject(error);
        });
    });
}

export const signupUser = (userData) => async (dispatch) => {
    // dispatch is there in case I want to call another action or method
    
    console.log('sesionAction.signupUser userData:');
    console.log(userData);

    return new Promise((resolve, reject) => {
        axios.post('/auth/signup', userData, {
            // If I want to get on xml format I must to set: 'Accept': 'application/xml'
            headers: {'Accept': 'application/json', 'Content-type': 'application/json'}
        }).then( response => {
            console.log('sesionAction.signupUser response:');
            console.log(response);

            let loginData = {email: userData.email, password: userData.password};
            dispatch(loginUser(loginData));

            //envioCorreo(decoded.sub, "Inicio de sesión", "Has iniciado sesión en Embudi");

            resolve(response);
        }).catch ( error => {
            console.log('sesionAction.signupUser error:');
            console.log(error);
            Swal.fire({icon: "error", title: error.response.data.errorTitle, text: error.response.data.errorDetail, toast: true});
            reject(error);
        });
    });
}

export const updateUser = (userData) => async (dispatch) => {
    // está dispatch en caso de que se quiera llamar a otra acción
    // DatosLogin debe ser del tipo Login.java en el back es decir {correo, contrasena}
    
    console.log('sesionAction.updateUser userData:');
    console.log(userData);

    return new Promise((resolve, reject) => {
        axios.put('/user', userData, {
            // If I want to get on xml format I must to set: 'Accept': 'application/xml'
            headers: {'Accept': 'application/json', 'Content-type': 'application/json'}
        }).then( response => {
            console.log('sesionAction.updateUser response:');
            console.log(response);

            //envioCorreo(decoded.sub, "Datos personales", "Has actualizado tus datos en Embudi");
            
            // update redux data user
            store.dispatch({
                type: SET_USER_SESSION,
                payload: response.data 
            });

            resolve(response);
        }).catch ( error => {
            console.log('sesionAction.updateUser error:');
            console.log(error);
            Swal.fire({icon: "error", title: error.response.data.errorTitle, text: error.response.data.errorDetail, toast: true});
            reject(error);
        });
    });
}

export const changePassword = (loginData) => async (dispatch) => {
    // dispatch is there in case I want to call another action or method
    // loginData must be src/dt/Login.java in the back, that is: {email, password, newPassword}
    
    //console.log('sesionAction.changePassword DatosLogin:');
    //console.log(loginData);

    return new Promise((resolve, reject) => {
        axios.post('/inicio/modifica', loginData, {
            // If I want to get on xml format I must to set: 'Accept': 'application/xml'
            headers: {'Accept': 'application/json', 'Content-type': 'application/json'}
        }).then( response => {
            //console.log('sesionAction.changePassword response:');
            //console.log(response);
            resolve(response);
        }).catch ( error => {
            console.log('sesionAction.changePassword error:');
            console.log(error);
            Swal.fire({icon: "error", title: "Error", text: "Error al cambiar la contraseña", toast: true});
            reject(error);
        });
    });
}

export const logoutUsuario = () => dispatch => {
    // remover el usuario del localStorage, remover del store, y quitar la autorización del Header
    localStorage.removeItem('token');
    localStorage.removeItem('uuid');
    setAuthToken(false); // en esta función remuevo la autorización de la cabecera (header) si le mando false
    dispatch({ // remover del store
        type: SET_SESSION,
        payload: { userSession: {}, logged: false, tryLogin: true } // al cerrar sesión tryLogin está bien en true, es para que no se abra el componente de Mis cursos o mi panel de estudiante
    });
}