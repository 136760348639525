import "bootstrap/dist/css/bootstrap.min.css"
import '@fortawesome/fontawesome-free/js/all'
import './App.css'; // must be after bootstrap css because this css has a custom color, see :root on this css
import Axios from 'axios';
//import {Router, Routes, Route } from 'react-router';

import store from './store';
import { Provider } from 'react-redux';
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import { PATH_HOME, PATH_LOGIN, PATH_MY_PRODUCTS, PATH_PRODUCT, PATH_PRODUCT_CREATE, PATH_PRODUCT_IMAGES, PATH_PRODUCT_UPDATE, PATH_SIGNUP, PATH_USER } from "./utils/path";
import SignupPage from "./pages/SignupPage";
import UserPage from "./pages/UserPage";
import { checkAuthToken } from "./utils/functions";
//import PrivateRoute from "./utils/PrivateRoute";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MyProductsPage from "./pages/products/MyProductsPage";
import ProductCreatePage from "./pages/products/ProductCreatePage";
import ProductUpdatePage from "./pages/products/ProductUpdatePage";
import ProductResourcePage from "./pages/products/ProductResourcePage";
import ProductPage from "./pages/products/ProductPage";

Axios.interceptors.request.use(function(config){
  //config.url = "http://localhost:2010/api" + config.url;
  // usando la configuración del archivo: .env.development.local
  let myUrl = config.url.indexOf('http') >= 0 ?
        config.url
    :
        `${process.env.REACT_APP_API_BASE_URL}${config.url}`;
  config.url = myUrl;
  //config.url = `${process.env.REACT_APP_API_BASE_URL}${config.url}`;
  return config;
});

checkAuthToken();

function App() {
  return (
      <BrowserRouter>
        <Provider store={store}>
          <Routes>
            {/*public paths*/}
            <Route path={PATH_HOME} element={<HomePage/>} />
            <Route path={PATH_LOGIN} element={<LoginPage/>} />
            <Route path={PATH_SIGNUP} element={<SignupPage/>} />
            <Route path={PATH_PRODUCT+"/:id"} element={<ProductPage/>} />
            
            {/*private paths. Logged in users*/}
            <Route path={PATH_USER} element={<UserPage/>} />
            <Route path={PATH_MY_PRODUCTS} element={<MyProductsPage/>} />
            <Route path={PATH_PRODUCT_CREATE} element={<ProductCreatePage/>} />
            <Route path={PATH_PRODUCT_UPDATE+"/:id"} element={<ProductUpdatePage/>} />
            <Route path={PATH_PRODUCT_IMAGES+"/:id"} element={<ProductResourcePage/>} />
            </Routes>
        </Provider>
    </BrowserRouter>
   
  );
}

export default App;
