import axios from "axios";
// eslint-disable-next-line
import { getErrorMessage, setAuthToken, tieneError, checkAuthToken, isNull } from "../utils/functions";
import Swal from 'sweetalert2';
import { PRODUCT_CURRENT, PRODUCT_SET_ALLDATA, PRODUCT_LOAD, FILE_SET, PRODUCT_RESOURCE_CREATE, PRODUCT_RESOURCE_DELETE, PRODUCT_RESOURCE_SET_MAIN, PRODUCT_SET_FILTERS } from "../const/actionType";

//export const URL_MSPRODUCT = "http://localhost:2011/api";
export const URL_MSPRODUCT = process.env.REACT_APP_MS_PRODUCT;

export const productStatusList = (first) => {
    let tmp = [
        { value: "Available", label: "Disponible" }, 
        { value: "Inactive", label: "Inactivo"}, 
        { value: "Sold", label: "Vendido" }, 
        { value: "Leased", label: "Arrendado"} ]; // Model Enum ProductStatus
    if (!isNull(first)) return [{ value: -1, label: first }, ...tmp];
    else return tmp;
}

export const productOperationList = (first) => {
    let tmp = [{ value: "Rent", label: "Alquiler" }, { value: "Sale", label: "Venta" } ]; // Model Enum ProductOperation
    if (!isNull(first)) return [{ value: -1, label: first }, ...tmp];
    else return tmp;
}

export const productStratumList = (first) => {
    let tmp = [ 
        { value: "S1", label: "E1" }, 
        { value: "S2", label: "E2" }, 
        { value: "S3", label: "E3" }, 
        { value: "S4", label: "E4" }, 
        { value: "S5", label: "E5" }, ]; // Model Enum ProductStratum
    if (!isNull(first)) return [{ value: -1, label: first }, ...tmp];
    else return tmp;
}

export const productTypeList = (first) => {
    let tmp = [ 
        { value: "1", label: "Casa" }, 
        { value: "2", label: "Departamento" }, 
        { value: "3", label: "Terreno" } ]; // Entity ProductType, check MS Product: src/main/resources/data.sql
    if (!isNull(first)) return [{ value: -1, label: first }, ...tmp];
    else return tmp;
}

export const loadMyProducts = (uuid, pageNumber, filters) => async(dispatch) => {
    // dispatch is there in case I want to call another action or method
    
    //console.log('productAction.loadMyProducts uuid:', uuid);

    return new Promise((resolve, reject) => {
        let parPageSize = "?pageSize=12";
        let parPageNumber = "&pageNumber=" + pageNumber;
        let parUuid = "";
        if (uuid) parUuid = "&uuid=" + uuid;
        let url = URL_MSPRODUCT + '/product/custom' + parPageSize + parPageNumber + parUuid;
        axios.post( url, filters , {
            // If I want to get in xml format I must to set: 'Accept': 'application/xml'
            headers: {'Accept': 'application/json', 'Content-type': 'application/json'}
        }).then( response => {
            //console.log('productAction.loadMyProducts response:', response);
            
            dispatch({
                type: PRODUCT_LOAD,
                payload: response.data
            });

            dispatch({
                type: PRODUCT_SET_FILTERS,
                payload: filters
            });
            
            resolve(response);
        }).catch ( error => {
            console.log('productAction.loadMyProducts error:', error);
            Swal.fire({icon: "error", title: "Error", text: "Error en la búsqueda, intente más tarde por favor", toast: true});
            reject(error);
        });
    });
}

export const productFindById = (productId) => async(dispatch) => {
    // dispatch is there in case I want to call another action or method
    
    //console.log('productAction.productFindById productId:', productId);

    if (!productId) {
        // set empty to product
        dispatch({
            type: PRODUCT_CURRENT,
            payload: {}
        });
        return;
    }

    return new Promise((resolve, reject) => {
        let url = URL_MSPRODUCT + '/product/' + productId;
        axios.get( url, {
            // If I want to get in xml format I must to set: 'Accept': 'application/xml'
            headers: {'Accept': 'application/json', 'Content-type': 'application/json'}
        }).then( response => {
            //console.log('productAction.productFindById response:', response);
            
            dispatch({
                type: PRODUCT_CURRENT,
                payload: response.data
            });
            
            resolve(response);
        }).catch ( error => {
            console.log('productAction.productFindById error:', error);
            Swal.fire({icon: "error", title: "Error", text: "Error en la búsqueda, intente más tarde por favor", toast: true});
            reject(error);
        });
    });
}

export const productGetAllDataById = (productId) => async(dispatch) => {
    // dispatch is there in case I want to call another action or method
    
    console.log('productAction.productGetAllDataById productId:', productId);

    if (!productId) {
        // set empty to product
        dispatch({
            type: PRODUCT_CURRENT,
            payload: {}
        });
        return;
    }

    return new Promise((resolve, reject) => {
        let url = URL_MSPRODUCT + '/product/alldata/' + productId;
        axios.get( url, {
            // If I want to get in xml format I must to set: 'Accept': 'application/xml'
            headers: {'Accept': 'application/json', 'Content-type': 'application/json'}
        }).then( response => {
            //console.log('productAction.productFindById response:', response);
            
            dispatch({
                type: PRODUCT_SET_ALLDATA,
                payload: response.data // POJO: dto/ProductAllData.java
            });
            
            resolve(response);
        }).catch ( error => {
            console.log('productAction.productGetAllDataById error:', error);
            Swal.fire({icon: "error", title: "Error", text: "Error en la búsqueda, intente más tarde por favor", toast: true});
            reject(error);
        });
    });
}

export const productCreate = (productData) => async(dispatch) => {
    // dispatch is there in case I want to call another action or method
    // productData must be of type Product Entity in the backend
    //console.log('productAction.productCreate productData:', productData);

    return new Promise((resolve, reject) => {
        let url = URL_MSPRODUCT + '/product';
        axios.post( url, productData , {
            // If I want to get on xml format I must to set: 'Accept': 'application/xml'
            headers: {'Accept': 'application/json', 'Content-type': 'application/json'}
        }).then( response => {
            console.log('productAction.productCreate response:', response);
            
            dispatch({
                type: PRODUCT_CURRENT,
                payload: response.data
            });
            Swal.fire({icon: "success", title: "Agregar", text: "Agregado exitosamente", toast: true});
            resolve(response);
        }).catch ( error => {
            console.log('productAction.productCreate error:');
            console.log(error);
            Swal.fire({icon: "error", title: error.response.data.errorTitle, text: error.response.data.errorDetail, toast: true});
            reject(error);
        });
    });
}

export const productUpdate = (productData) => async(dispatch) => {
    // dispatch is there in case I want to call another action or method
    // productData must be of type Product Entity in the backend
    
    //console.log('productAction.productUpdate productData:', productData);

    return new Promise((resolve, reject) => {
        let url = URL_MSPRODUCT + '/product';
        axios.put( url, productData , {
            // If I want to get on xml format I must to set: 'Accept': 'application/xml'
            headers: {'Accept': 'application/json', 'Content-type': 'application/json'}
        }).then( response => {
            //console.log('productAction.productUpdate response:', response);
            
            dispatch({
                type: PRODUCT_CURRENT,
                payload: response.data
            });

            Swal.fire({icon: "success", title: "Actualizar", text: "Actualizado exitosamente", toast: true});

            resolve(response);
        }).catch( error => {
            console.log('productAction.productUpdate error:');
            console.log(error);
            Swal.fire({icon: "error", title: error.response.data.errorTitle, text: error.response.data.errorDetail, toast: true});
            reject(error);
        });
    });
}

export const uploadFile = (file, showMessage, onUploadProgress) => async(dispatch) => {
    
    return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append("file", file);
        let url = URL_MSPRODUCT + "/file/uploadFile";

        axios.post(url, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress,
        }).then(response => {
            console.log("productAction.uploadFile response:", response);
            dispatch({
                type: FILE_SET,
                payload: response.data
            });
            if ( showMessage === true ) Swal.fire({icon: "success", title: "Archivo", text: "Archivo registrado", toast: true});
            resolve(response);

        }).catch( error => {
            //Swal.fire({icon: "error", title: "Error", text: "No se pudo registrar el archivo", toast: true});
            console.log("productAction.uploadFile error:", error);
            reject(error);
        });
    });
}

export const productResourceCreate = (resourceData) => async(dispatch) => {
    // dispatch is there in case I want to call another action or method
    // resourceData must be of type ProductResource Entity in the backend
    //console.log('productAction.productResourceCreate resourceData:', resourceData);

    return new Promise((resolve, reject) => {
        let url = URL_MSPRODUCT + '/product/resource';
        axios.post( url, resourceData , {
            // If I want to get on xml format I must to set: 'Accept': 'application/xml'
            headers: {'Accept': 'application/json', 'Content-type': 'application/json'}
        }).then( response => {
            //console.log('productAction.productResourceCreate response:', response);
            
            dispatch({
                type: PRODUCT_RESOURCE_CREATE,
                payload: response.data
            });
            Swal.fire({icon: "success", title: "Agregar", text: "Agregado", toast: true});
            resolve(response);
        }).catch ( error => {
            console.log('productAction.productResourceCreate error:', error);
            Swal.fire({icon: "error", title: error.response.data.errorTitle, text: error.response.data.errorDetail, toast: true});
            reject(error);
        });
    });
}

export const productResourceDelete = (productResourceId) => async(dispatch) => {
    // dispatch is there in case I want to call another action or method
    //console.log('productAction.productResourceDelete productResourceId:', productResourceId);

    return new Promise((resolve, reject) => {
        let url = URL_MSPRODUCT + '/product/resource/' + productResourceId;
        axios.delete( url, {
            // If I want to get on xml format I must to set: 'Accept': 'application/xml'
            headers: {'Accept': 'application/json', 'Content-type': 'application/json'}
        }).then( response => {
            //console.log('productAction.productResourceDelete response:', response);
            
            dispatch({
                type: PRODUCT_RESOURCE_DELETE,
                payload: productResourceId
            });
            Swal.fire({icon: "success", title: "Eliminar", text: "Eliminado", toast: true});
            resolve(response);
        }).catch ( error => {
            console.log('productAction.productResourceDelete error:', error);
            Swal.fire({icon: "error", title: error.response.data.errorTitle, text: error.response.data.errorDetail, toast: true});
            reject(error);
        });
    });
}

export const productResourceSetMain = (productResourceId) => async(dispatch) => {
    // dispatch is there in case I want to call another action or method
    //console.log('productAction.productSetMain productResourceId:', productResourceId);

    return new Promise((resolve, reject) => {
        let url = URL_MSPRODUCT + '/product/resource/setMain/' + productResourceId;
        axios.put( url, {
            // If I want to get on xml format I must to set: 'Accept': 'application/xml'
            headers: {'Accept': 'application/json', 'Content-type': 'application/json'}
        }).then( response => {
            //console.log('productAction.productSetMain response:', response);
            dispatch({
                type: PRODUCT_RESOURCE_SET_MAIN,
                payload: productResourceId
            });
            Swal.fire({icon: "success", title: "Cambiar portada", text: "Cambio realizado", toast: true});
            resolve(response);
        }).catch ( error => {
            console.log('productAction.productSetMain error:', error);
            Swal.fire({icon: "error", title: error.response.data.errorTitle, text: error.response.data.errorDetail, toast: true});
            reject(error);
        });
    });
}