import React from 'react';
import { Navbar } from 'react-bootstrap';

const Foot = () => {
    return (
        <>
            
            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
            <Navbar className='justify-content-center' bg='dark' variant='dark' expand='lg'>
                <Navbar.Text>
                    Desarrollado por Ronny Valles.
                </Navbar.Text>
            </Navbar>
        </>
    );
}
 
export default Foot;