import { SET_SHOW_MENU, SET_SHOW_MODAL } from '../const/actionType';

const initialState = {
    showMenu: true, // true o false, para mostrar/ocultar el menú en alguna vista. Se lo modifica con botón de menú en el header
    showModal: false
}

export default function generalReducer(state = initialState, action) {
    switch(action.type) {
        case SET_SHOW_MENU:
            return {
                ...state,
                showMenu: action.payload
            }
        case SET_SHOW_MODAL:
            return {
                ...state,
                showModal: action.payload
            }
        default: return state;
    }
}