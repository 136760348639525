import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { productResourceDelete, productResourceSetMain } from '../../actions/productAction';
import Swal from 'sweetalert2';
import { NavLink } from 'react-router-dom';

const ProductResourceCard = ({resource}) => {

  const dispatch = useDispatch();

  const deleteItem = () => {
    Swal.fire({ 
      icon:"question", title:"Eliminar", text:"¿Deseas eliminarlo?", confirmButtonText: "Eliminar", 
      showCancelButton: true, cancelButtonText: "No"
    }).then(async (result) => {
      if (result.value) {
        dispatch(productResourceDelete(resource.productResourceId));
      } else {
        Swal.fire({icon: "success", title: "", text: "No se elimina", toast: true});
      }
    });
  }

  const setMain = () => {
    dispatch(productResourceSetMain(resource.productResourceId))
  }

return (<>
    <Card 
      //style={{ width: '18rem' }}
      className='masonry-card shadow p-3 mb-5 bg-white rounded'
    >
      <Card.Img variant="top"
        //style={{ height: '13rem' }}
        className='masonry-img'
        src={resource.file? resource.file.uriOpen: "/link-640x640.webp"} 
      />
      <Card.Body>
        <Card.Title>{resource.name? resource.name: ""}</Card.Title>
        <Card.Text></Card.Text>
        <Button variant="outline-danger" onClick={deleteItem}>
          <span className="icon is-small">
            <i className="fa-regular fa-trash-can"></i>
          </span>
        </Button>
        { resource.file && !resource.main &&
          <Button className='ml-0.5' variant="outline-primary" onClick={setMain}>Portada</Button>
        }
        { resource.file && resource.main &&
          <Button className='ml-0.5' variant="success">Soy la portada</Button>
        }

        { !resource.file && 
          <Button className='ml-0.5' variant="outline-primary"
            as={NavLink} to={resource.url} target='_blank'
          >
            Abrir
          </Button>
        }
        
      </Card.Body>
    </Card>
</>);
}
 
export default ProductResourceCard;