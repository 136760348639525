import React, { useEffect } from 'react';
import Header from '../../components/commons/Header';
import Foot from '../../components/commons/Foot';
import ProductCreateForm from '../../components/product/ProductCreateForm';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { PATH_HOME, PATH_MY_PRODUCTS } from '../../utils/path';
import { NavLink, useNavigate } from 'react-router-dom';
import { productFindById } from '../../actions/productAction';

const ProductCreatePage = () => {

    const productReducer = useSelector (state => state.product.product);
    const dispatch = useDispatch();

    const logged = useSelector(state => state.sesion.logged);
    const navigate = useNavigate();

    useEffect(() => {
        if (!logged) {
            navigate(PATH_HOME); // Here only with logged users
        }
    // eslint-disable-next-line
    }, [logged]);

    useEffect(() => {
        dispatch(productFindById(null));
        // eslint-disable-next-line
    }, []);

    return (
    <>
        <Header/>
        <Container className='mt-5'>
            <Row>
                <Col sm="12" md={{span:8, offset:2}} lg={{span:6, offset:3}}>
                    <Card body>
                        <Row>
                            <Col>
                                {
                                    !productReducer.productoId && <h3>Agregar</h3>
                                }
                                {
                                    productReducer.productoId && <h3>Actualizar Datos</h3>
                                }
                            </Col>
                            <Col className="d-flex justify-content-end">
                                <Button as={NavLink} to={PATH_MY_PRODUCTS}>Regresar</Button>
                            </Col>
                        </Row>
                        <ProductCreateForm />
                    </Card>
                </Col>
            </Row>
        </Container>
        
        <Foot/>
    </>
        
    );
}
 
export default ProductCreatePage;