import React, { useEffect } from 'react';
import Header from '../../components/commons/Header';
import Foot from '../../components/commons/Foot';
import { Button, Carousel, Col, Container, Navbar, Row } from 'react-bootstrap';
import { NavLink, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { productGetAllDataById } from '../../actions/productAction';

const ProductPage = () => {

    const { id } = useParams();
    const dispatch = useDispatch();

    const product = useSelector(state => state.product.product);
    const resourceList = useSelector(state => state.product.resourceList);

    useEffect(() => {
        if (id) {
            dispatch(productGetAllDataById(id));
        }
        // eslint-disable-next-line
    }, []);

return (<>
    <Header/>
    <Container className='mt-1'>
    <Row>
    <Col
        className='galery-card shadow p-3 mb-5 bg-white rounded'
        sm="12" 
        md={{span:8, offset:2}} lg={{span:6, offset:3}}
    >
        { resourceList && resourceList.length > 0 && 
            <Row>
                <Carousel
                    prevLabel='Anterior'
                    nextLabel='Siguiente'
                    variant='dark'
                    nextIcon={
                        <Button variant="primary">
                            <span className="icon is-large">
                                <i className="fa-solid fa-chevron-right"></i>
                            </span>
                        </Button>
                    }
                    prevIcon={
                        <Button variant="primary">
                            <span className="icon is-large">
                                <i className="fa-solid fa-chevron-left"></i>
                            </span>
                        </Button>
                    }
                >
                    {resourceList.map((r, index) => (
                        <Carousel.Item key={index}>
                            { r.file?
                                    <img
                                        className="d-block mx-auto"
                                        src={r.file? r.file.uriOpen: "/link-640x640.webp"}
                                        alt={`Slide ${index}`}
                                        //style={{ objectFit: 'cover', height: '500px' }} // Adjust height as needed
                                        style={{ objectFit: 'contain', width: '100%', height: '30rem' }} // Adjust height as needed
                                    />
                                :
                                    <iframe
                                        style={{ objectFit: 'contain', width: '100%', height: '30rem' }}
                                        className="d-block mx-auto"
                                        src={r.embedUrl}
                                        frameborder='0'
                                        allow='autoplay; encrypted-media'
                                        allowfullscreen
                                        title='video'
                                    />
                            }
                            {r.caption && (
                                <Carousel.Caption>
                                    <h3>{r.name}</h3>
                                </Carousel.Caption>
                            )}
                        </Carousel.Item>
                    ))}
                </Carousel>
            </Row>
        }
            
        <Navbar bg="primary" data-bs-theme="dark">
            <Container>
                <Col className='d-flex align-items-center justify-content-center'>
                    <Navbar.Brand>{"$ " + product.publicPrice}</Navbar.Brand>
                    
                </Col>
                { product.code?
                    <Col className='d-flex align-items-center justify-content-center'>
                        <Navbar.Brand>{"Cod: " + product.code}</Navbar.Brand>
                    </Col>
                    :<></>
                }
            </Container>
        </Navbar>
        <Row className='mt-2'>
            <h3>{product.name}</h3>
        </Row>
        <Row>
            <Col xs={6}>
                <span className="icon is-small">
                  <i className="fa-solid fa-house" /><text>{" " + product.livableArea + " m" }<sup>2</sup></text>
                </span>
            </Col>
            <Col xs={6}>
                <span className="icon is-small">
                  <i className="fa-solid fa-expand" /><text>{" " + product.landArea + " m"}<sup>2</sup></text>
                </span>
            </Col>
            <Col xs={6}>
                <span className="icon is-small">
                  <i className="fa-solid fa-bed" /><text>{" " + product.bedrooms + " Dormitorio (s)" }</text>
                </span>
            </Col>
            <Col xs={6}>
                <span className="icon is-small">
                  <i className="fa-solid fa-toilet" /><text>{" " + product.bathrooms + " Baño (s)"}</text>
                </span>
            </Col>
            <Col xs={6}>
                <span className="icon is-small">
                  <i className="fa-solid fa-warehouse" /><text>{" " + product.parkingSpots + " Garaje (s)"}</text>
                </span>
            </Col>
            <Col xs={6}>
                <span className="icon is-small">
                  <i className="fa-solid fa-location-dot" /><text>{" " + product.sector}</text>
                </span>
            </Col>
        </Row>
        <Row className='mt-2'>
            <p>{product.longDescription}</p>
        </Row>
        <Row className='mt-2'>
            { product.user && product.user.phone && 

                <Col className='d-flex align-items-center justify-content-center'>
                    <Button variant='success'
                        title='Enviar un mensaje al vendedor'
                        as={NavLink} 
                        to={`https://api.whatsapp.com/send?phone=${product.user.phone}&text=Me%20interesa%20esta%20propiedad%20${window.location.href}`}
                    >
                        <span className="icon is-small">
                            <i className="fa-brands fa-whatsapp" />
                        </span>
                        <span className='ml-0.5'>Contactar</span>
                    </Button>
                </Col>
            }
            <Col className='d-flex align-items-center justify-content-center'>
                <Button variant='outline-primary'
                    onClick={() => {navigator.clipboard.writeText(window.location.href)}}
                    title='Copiar este enlace (link) al portapapeles'
                >
                    <span className="icon is-small">
                        <i className="fas fa-clipboard"></i>
                    </span>
                    <span className='ml-0.5'>Copiar enlace</span>
                </Button>
            </Col>
        </Row>
    </Col>
    </Row>
    
    </Container>
    <Foot/>
</>);
}
 
export default ProductPage;