import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
// eslint-disable-next-line
import { isNull, isObjEmpty } from '../../utils/functions';
import { useDispatch, useSelector } from 'react-redux';
import { setShowModal } from '../../actions/generalAction';
import { productResourceCreate, uploadFile } from '../../actions/productAction';
import Resizer from "react-image-file-resizer";

const ProductResourceForm = () => {

const [file, setFile] = useState({});
const [name, setName] = useState("");
const [url, setUrl] = useState("");
const [autoNamed, setAutoNamed] = useState(false); // is used for set a resource name only if the user don't enter any text (name)
//const [readOnly, setReadOnly] = useState(false);
const [errors, setErrors] = useState({});
const showModal = useSelector(state => state.general.showModal);
const product = useSelector(state => state.product.product);
//const fileReducer = useSelector(state => state.product.file);
const dispatch = useDispatch();

const uploadResource = () => {

    let errors = {};
    setErrors({});
    let ok = true;
    
    if (!file.name && isNull(url)) { errors.file = "Selecciona un archivo o pon el enlace"; ok = false; } // doesn't work functions.isObjEmpty
    if (file.name && !isNull(url)) { errors.url = "Solo puede seleccionar un archivo o poner el enlace, no ambos"; ok = false; }
    if (file.type && !file.type.includes("image")) { errors.file = "Debe ser una foto o imagen"; ok = false; }

    setErrors(errors);
    console.log("ProductResourceForm.uploadResource file:", file);
    if (ok) {
        //console.log("ProductResourceForm.uploadResource ok");
        getResource().then(response => {
            //console.log("ProductResource.uploadResource response:", response);
            dispatch(productResourceCreate(response));
            closeModal();
        }).catch(error => {
            console.log("ProductResource.uploadResource error:", error);
        });
    }
}

const getResource = async() => {
    let resourceTmp = {
        product: { productId: product.productId },
        order: 0,
        name: name,
        main: false,
        description: "",
        file: null, 
        url: null
    };
    if (file.name) {
        return new Promise((resolve, reject) => {
            dispatch(uploadFile(file, false))
            .then(response => {
                //console.log("ProductResourceForm.getResource response:", response);
                resourceTmp.file = response.data;
                if (isNull(resourceTmp.description)) delete resourceTmp.description;
                resolve(resourceTmp);
            }).catch(error => {
                console.log("ProductResourceForm.getResource error:", error);
                reject(error);
            });
        });
    } else {
        resourceTmp.url = url;
        return resourceTmp;
    }
}

const resizeFile = (file) => 
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            1080,
            1080,
            "WEBP",
            70,
            0,
            (uri) => {
                //console.log("uri:", uri);
                resolve(uri);
            },
            "file"
        );
    });

const selectFile = async(event) => {
    if (event.target.files && event.target.files.length > 0) {
        //setFile(event.target.files[0]);
        //console.log("ProductResourceForm.selectFile event.target.files[0]:", event.target.files[0]);
        if (event.target.files[0].type.includes("image")) {
            setFile(await resizeFile(event.target.files[0]));
            setErrors({});
            if (isNull(name) || autoNamed === true) {
                setName(event.target.files[0].name);
                setAutoNamed(true);
            }
        } else {
            setErrors({...errors, file: "Debe ser una imagen o foto"});
        }
    }
}

const closeModal = () => {
    dispatch(setShowModal(false));
    setErrors({});
    setFile({});
    setName("");
    setUrl("");
    setAutoNamed(false);
}

return (<>
    
    <div
      className="modal show"
      style={{ display: 'block', position: 'initial' }}
    >
        <Modal show={showModal} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>Agregar Imagen</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form>
                    <Form.Group control="file">
                        <Form.Label>Imagen</Form.Label>
                        <Form.Control type='file' 
                            name="file"
                            //accept="image/*"
                            //capture
                            multiple={false}
                            //value={file} 
                            onChange={ (e) => selectFile(e) }
                            placeholder='Agrega el archivo'
                            isInvalid={errors.file}
                            //readOnly={readOnly}
                        />
                        <Form.Control.Feedback type='invalid'>{errors.file}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group control="url" className='mt-3'>
                        <Form.Label>Enlace/Link</Form.Label>
                        <Form.Control //type='text' 
                            name="url"
                            value={url} 
                            onChange={e => setUrl(e.target.value)}
                            placeholder='enlace de video'
                            isInvalid={errors.name}
                            //readOnly={readOnly}
                        />
                        <Form.Control.Feedback type='invalid'>{errors.url}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group control="name" className='mt-3'>
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control //type='text' 
                            name="name"
                            value={name} 
                            onChange={e => {setName(e.target.value); setAutoNamed(false);}}
                            placeholder='descripción corta'
                            isInvalid={errors.name}
                            //readOnly={readOnly}
                        />
                        <Form.Control.Feedback type='invalid'>{errors.name}</Form.Control.Feedback>
                    </Form.Group>
                </Form>

            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>Cancelar</Button>
                <Button variant="primary" onClick={uploadResource}>Agregar</Button>
            </Modal.Footer>
        </Modal>
    </div>
</>);
}
 
export default ProductResourceForm;