import React, { useEffect } from 'react';
import Header from '../components/commons/Header';
import Foot from '../components/commons/Foot';
import { Col, Container, Row } from 'react-bootstrap';
import Home from '../components/home/Home';
import FilterSideMenu from '../components/product/FilterSideMenu';

const HomePage = () => {

    useEffect(() => {
        //console.log("Home. " + new Date());
    // eslint-disable-next-line
    }, []);

    return (
    <>
        <Header/>
        <Container fluid 
            //className='bg-gray'
        >
            <Row>
                <Col xxl={2} xl={3} lg={4} md={4} sm={0} className='bg-white .d-sm-none .d-md-block'>
                    <FilterSideMenu userFilter={false} />
                </Col>
                <Col xxl={10} xl={9} lg={8} md={8} sm={12} 
                    //className='bg-gray'
                >
                    
                    {/*<nav className='d-flex justify-content-center'>
                        <h2>Mis Propiedades</h2>
                    </nav>*/}
                    <br/>
                    <Home/>
                </Col>
            </Row>
        </Container>
        <Foot/>
    </>
    );
}
 
export default HomePage;