import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { FROM, NEVER, UNTIL } from '../../const/actionType';
// eslint-disable-next-line
import { getAreasList, getEntireNumbersList, getPricesList, isNull, isSelected, noSelected } from '../../utils/functions';
import { loadMyProducts, productTypeList } from '../../actions/productAction';
import { useDispatch, useSelector } from 'react-redux';

const FilterSideMenu = ({userFilter}) => {

    const user = useSelector(state => state.sesion.user);
    const dispatch = useDispatch();

    const [productTypeId, setProductTypeId] = useState(NEVER);
    const [publicPriceMin, setPublicPriceMin] = useState(NEVER);
    const [publicPriceMax, setPublicPriceMax] = useState(NEVER);
    const [landAreaMin, setLandAreaMin] = useState(NEVER);
    const [landAreaMax, setLandAreaMax] = useState(NEVER);
    const [livableAreaMin, setLivableAreaMin] = useState(NEVER);
    const [livableAreaMax, setLivableAreaMax] = useState(NEVER);
    const [bathroomsMin, setBathroomsMin] = useState(NEVER);
    const [bathroomsMax, setBathroomsMax] = useState(NEVER);
    const [bedroomsMin, setBedroomsMin] = useState(NEVER);
    const [bedroomsMax, setBedroomsMax] = useState(NEVER);

    const updateFilters = () => {
        let filters = [];
        filters = addFilter(filters, 'type', productTypeId, null, null);
        filters = addFilter(filters, 'publicPrice', null, publicPriceMin, publicPriceMax);
        filters = addFilter(filters, 'landArea', null, landAreaMin, landAreaMax);
        filters = addFilter(filters, 'livableArea', null, livableAreaMin, livableAreaMax);
        filters = addFilter(filters, 'bathrooms', null, bathroomsMin, bathroomsMax);
        filters = addFilter(filters, 'bedrooms', null, bedroomsMin, bedroomsMax);
        console.log("FilterSideMenu.find filters:", filters);
        let filterByUser = "";
        if (userFilter) filterByUser = user.uuid;

        dispatch(loadMyProducts(filterByUser, 0, filters))
            .then(response => {
                //console.log("MyProductTable.useEffect[user] response: ", response);
            }).catch(error => {
                console.log("MyProductTable.useEffect[user] error: ", error);
            });

    }

    const addFilter = (filters, type, value, min, max) => {
        //console.log(type, value, min, max);
        if (isSelected(value)) 
            return [...filters, { type: type, value: value, min: null, max: null }];
        if (isSelected(min) && isSelected(max)) 
            return [...filters, { type: type, value: null, min: min.replace(".", ''), max: max.replace(".", '') }];
            //return [...filters, { type: type, value: null, min: min, max: max }];
        if (isSelected(min) && noSelected(max)) 
            return [...filters, { type: type, value: null, min: min.replace(".", ''), max: null }];
            //return [...filters, { type: 'publicPrice', value: null, min: min, max: null }];
        if (noSelected(min) && isSelected(max)) 
            return [...filters, { type: type, value: null, min: null, max: max.replace(".", '') }];
            //return [...filters, { type: 'publicPrice', value: null, min: null, max: max }];
        return filters;
    }

return (<>
    <Form //onSubmit={updateFilters}
        //className='bg-white'
    >

        <Col className="d-flex justify-content-center mb-3">
            <Button variant='primary' className='mt-4 px-5' onClick={updateFilters}>Buscar</Button>
        </Col>

        <Form.Group control="type" className='mb-4'>
            <Form.Label className='blacked ml-0.5'>Tipo</Form.Label>
                <Form.Select 
                    aria-label="Default select example" 
                    name="type"
                    value={productTypeId}
                    onChange={ e => setProductTypeId(e.target.value)}>
                    {
                        productTypeList(NEVER).map(t =>(
                            <option value={t.value} key={t.value}>{t.label}</option>
                        ))
                    }
                </Form.Select>
        </Form.Group>

        <Form.Group control="publicPrice" className='mt-4'>
            <Form.Label className='blacked ml-0.5 mb-0'>Precio ($)</Form.Label>
            <Row>
            <Col>
                {/*<Form.Label column="sm">Desde</Form.Label>*/}
                <Form.Select 
                    aria-label="Default select example" 
                    name="publicPriceMin"
                    value={publicPriceMin}
                    onChange={ (e) => setPublicPriceMin(e.target.value) }
                >
                    {
                        getPricesList(FROM).map( num =>(
                            <option value={num} key={num}>{num}</option>
                        ))
                    }
                </Form.Select>
            </Col>
            <Col>
            {/*<Form.Label column="sm">Hasta</Form.Label>*/}
                <Form.Select 
                    aria-label="Default select example" 
                    name="publicPriceMax"
                    value={publicPriceMax}
                    onChange={ (e) => setPublicPriceMax(e.target.value) }
                >
                    {
                        getPricesList(UNTIL).map( num =>(
                            <option value={num} key={num}>{num}</option>
                        ))
                    }
                </Form.Select>
            </Col>
            </Row>
        </Form.Group>

        <Form.Group control="landArea" className='mt-4'>
            <Form.Label className='blacked ml-0.5 mb-0'>Área de terreno (m2)</Form.Label>
            <Row>
            <Col>
                {/*<Form.Label column="sm">Desde</Form.Label>*/}
                <Form.Select 
                    aria-label="Default select example" 
                    name="landAreaMin"
                    value={landAreaMin}
                    onChange={ (e) => setLandAreaMin(e.target.value) }
                >
                    {
                        getAreasList(FROM).map( num =>(
                            <option value={num} key={num}>{num}</option>
                        ))
                    }
                </Form.Select>
            </Col>
            <Col>
            {/*<Form.Label column="sm">Hasta</Form.Label>*/}
                <Form.Select 
                    aria-label="Default select example" 
                    name="landAreaMax"
                    value={landAreaMax}
                    onChange={ (e) => setLandAreaMax(e.target.value) }
                >
                    {
                        getAreasList(UNTIL).map( num =>(
                            <option value={num} key={num}>{num}</option>
                        ))
                    }
                </Form.Select>
            </Col>
            </Row>
        </Form.Group>

        <Form.Group control="livableArea" className='mt-4'>
            <Form.Label className='blacked ml-0.5 mb-0'>Área de construcción (m2)</Form.Label>
            <Row>
            <Col>
                {/*<Form.Label column="sm">Desde</Form.Label>*/}
                <Form.Select 
                    aria-label="Default select example" 
                    name="livableAreaMin"
                    value={livableAreaMin}
                    onChange={ (e) => setLivableAreaMin(e.target.value) }
                >
                    {
                        getAreasList(FROM).map( num =>(
                            <option value={num} key={num}>{num}</option>
                        ))
                    }
                </Form.Select>
            </Col>
            <Col>
            {/*<Form.Label column="sm">Hasta</Form.Label>*/}
                <Form.Select 
                    aria-label="Default select example" 
                    name="livableAreaMax"
                    value={livableAreaMax}
                    onChange={ (e) => setLivableAreaMax(e.target.value) }
                >
                    {
                        getAreasList(UNTIL).map( num =>(
                            <option value={num} key={num}>{num}</option>
                        ))
                    }
                </Form.Select>
            </Col>
            </Row>
        </Form.Group>

        <Form.Group control="bedrooms" className='mt-4'>
            <Form.Label className='blacked ml-0.5 mb-0'>Habitaciones</Form.Label>
            <Row>
            <Col>
                {/*<Form.Label column="sm">Desde</Form.Label>*/}
                <Form.Select 
                    aria-label="Default select example" 
                    name="bedroomsMin"
                    value={bedroomsMin}
                    onChange={ (e) => setBedroomsMin(e.target.value) }
                >
                    {
                        getEntireNumbersList(FROM, 1, 8).map( num =>(
                            <option value={num} key={num}>{num}</option>
                        ))
                    }
                </Form.Select>
            </Col>
            <Col>
            {/*<Form.Label column="sm">Hasta</Form.Label>*/}
                <Form.Select 
                    aria-label="Default select example" 
                    name="bedroomsMax"
                    value={bedroomsMax}
                    onChange={ (e) => setBedroomsMax(e.target.value) }
                >
                    {
                        getEntireNumbersList(UNTIL, 1, 8).map( num =>(
                            <option value={num} key={num}>{num}</option>
                        ))
                    }
                </Form.Select>
            </Col>
            </Row>
        </Form.Group>

        <Form.Group control="bathrooms" className='mt-4'>
            <Form.Label className='blacked ml-0.5 mb-0'>Baños</Form.Label>
            <Row>
            <Col>
                {/*<Form.Label column="sm">Desde</Form.Label>*/}
                <Form.Select 
                    aria-label="Default select example" 
                    name="bathroomsMin"
                    value={bathroomsMin}
                    onChange={ (e) => setBathroomsMin(e.target.value) }
                >
                    {
                        getEntireNumbersList(FROM, 1, 8).map( num =>(
                            <option value={num} key={num}>{num}</option>
                        ))
                    }
                </Form.Select>
            </Col>
            <Col>
            {/*<Form.Label column="sm">Hasta</Form.Label>*/}
                <Form.Select 
                    aria-label="Default select example" 
                    name="bathroomsMax"
                    value={bathroomsMax}
                    onChange={ (e) => setBathroomsMax(e.target.value) }
                >
                    {
                        getEntireNumbersList(UNTIL, 1, 8).map( num =>(
                            <option value={num} key={num}>{num}</option>
                        ))
                    }
                </Form.Select>
            </Col>
            </Row>
        </Form.Group>

        <Col className="d-flex justify-content-center">
            <Button variant='primary' className='mt-4 px-5' onClick={updateFilters}>Buscar</Button>
        </Col>
    </Form>
    <br/><br/>
</>);
}
 
export default FilterSideMenu;