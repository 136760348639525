import React, { useEffect, useState } from 'react';
import { Alert, Button, Image, Pagination, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { loadMyProducts } from '../../actions/productAction';
import { NavLink } from 'react-router-dom';
import { PATH_PRODUCT, PATH_PRODUCT_IMAGES, PATH_PRODUCT_UPDATE } from '../../utils/path';

const MyProductTable = () => {

    const user = useSelector( state => state.sesion.user );
    const productList = useSelector ( state => state.product.productList );
    const pageNumber = useSelector ( state => state.product.pageNumber );
    //const pageSize = useSelector ( state => state.product.pageSize );
    const totalPages = useSelector ( state => state.product.totalPages );
    const productFilters = useSelector ( state => state.product.productFilters );

    const dispatch = useDispatch();

    // Pagination
    //const [pageNumber, setPageNumber] = useState(0);
    const [items, setItems] = useState([]);
    useEffect(() => {
        // get an array of 'totalPages' size , the content doesn't matter.
        // I need use it to render the pagination buttons: items.map ( <Pagination.Item ...)
        if (productList && productList.length>0 && items.length === 0) {
            let vec = [];
            for (let number = 1; number <= totalPages; number++) {
                vec.push(number);
            }
            setItems(vec);
        }
    // eslint-disable-next-line
    }, [productList]);
    
    useEffect(() => {
        if (user && user.uuid) {
            updateProductList(0);
        }
    // eslint-disable-next-line
    }, [user]);

    const updateProductList = (numPag) => {
        //console.log("MyProductTable.updateProductList numPag:", numPag);
        //setPageNumber(numPag);
        dispatch(loadMyProducts(user.uuid, numPag, productFilters))
            .then(response => {
                //console.log("MyProductTable.useEffect[user] response: ", response);
            }).catch(error => {
                console.log("MyProductTable.useEffect[user] error: ", error);
            });
    }

if (productList.length === 0) return (
    <>
        <br/><br/>
        <Alert variant='info'>
            No se encuentran propiedades
        </Alert>
    </>
);

return (<>
    <Table>
        <thead>
            <tr>
                <th></th>
                <th>Nombre</th>
                <th>Sector</th>
                <th>Precio</th>
                <th>Acciones</th>
            </tr>
        </thead>
        <tbody>
            {
                productList.map( p => (
                    <tr  key={p.product.productId}>
                        <td><Image className='myproduct-img' src={p.file? p.file.uriOpen: "/home_icon.png"} rounded /></td>
                        <td className="align-middle">{p.product.name}</td>
                        <td className="align-middle">{p.product.sector}</td>
                        <td className="align-middle">{p.product.publicPrice}</td>
                        <td className="align-middle">
                            <Button variant="link" title='Editar los datos de la propiedad'
                                as={NavLink} to={PATH_PRODUCT_UPDATE+"/"+p.product.productId}
                            >
                                <span className="icon is-small">
                                    <i className="fas fa-edit"></i>
                                </span>
                            </Button>
                            <Button variant="link" title='Cargar las imágenes o enlaces (links)'
                                as={NavLink} to={PATH_PRODUCT_IMAGES+"/"+p.product.productId}
                            >
                                <span className="icon is-small">
                                    <i className="fa-regular fa-images"></i>
                                </span>
                            </Button>
                            <Button variant="link" title='Ver esta propiedad con la vista al público'
                                as={NavLink} to={PATH_PRODUCT+"/"+p.product.productId} target='blank'
                            >
                                <span className="icon is-small">
                                    <i className="fa-regular fa-eye"></i>
                                </span>
                            </Button>
                        </td>
                    </tr>
                ))
            }
            
        </tbody>
    </Table>
    
    <br/>
    <nav className='d-flex justify-content-center'>
        <Pagination>
        {
            items.map( (i, index) => (
                <Pagination.Item key={index} 
                    active={index === pageNumber} 
                    onClick={() => updateProductList(index)}
                >
                    {index+1}
                </Pagination.Item>
            ))
        }
        </Pagination>
    </nav>
</>);
}
 
export default MyProductTable;