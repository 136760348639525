import React, { useEffect } from 'react';
import Header from '../../components/commons/Header';
import Foot from '../../components/commons/Foot';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import { PATH_HOME, PATH_PRODUCT_CREATE } from '../../utils/path';
import MyProductTable from '../../components/product/MyProductTable';
import { useDispatch, useSelector } from 'react-redux';
import FilterSideMenu from '../../components/product/FilterSideMenu';
import { productFindById } from '../../actions/productAction';

const MyProductsPage = () => {

    const logged = useSelector(state => state.sesion.logged);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(productFindById(null));
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!logged) {
            navigate(PATH_HOME); // Here only with logged users
        }
    // eslint-disable-next-line
    }, [logged]);

    return (
    <>
        <Header/>
        <br/>
        <Container fluid>
            <Row>
                <Col xxl={2} xl={3} lg={4} md={6} sm={0}>
                    <FilterSideMenu userFilter={true} />
                </Col>
                <Col xxl={10} xl={9} lg={8} md={6} sm={12}>
                    <Row>
                        <Col sm={10}>
                            <nav className='d-flex justify-content-center'>
                                <h2>Mis Propiedades</h2>
                            </nav>
                        </Col>
                        <Col className="d-flex justify-content-end">
                            <Button variant="primary" as={NavLink} to={PATH_PRODUCT_CREATE}>Agregar</Button>
                        </Col>
                    </Row>
                    <br/>
                    <MyProductTable/>
                </Col>
            </Row>
            
        </Container>
        <Foot/>
    </>
    );
}
 
export default MyProductsPage;