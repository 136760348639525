import { PRODUCT_CURRENT, PRODUCT_SET_ALLDATA, PRODUCT_LOAD, FILE_SET, PRODUCT_RESOURCE_CREATE, PRODUCT_RESOURCE_DELETE, PRODUCT_RESOURCE_SET_MAIN, PRODUCT_SET_FILTERS } from '../const/actionType';

const initialState = {
    productList: [], // list of products 
    pageNumber: 0,
    pageSize: 0,
    totalItems: 0,
    totalPages: 0,
    product: {}, // current product
    resourceList: [], // list of ProductResource Entity
    file: undefined,
    productFilters: []
}

export default function productReducer(state = initialState, action) {
    switch(action.type) {
        case PRODUCT_LOAD:
            return {
                ...state,
                productList: action.payload.content,
                pageNumber: action.payload.pageNumber,
                pageSize: action.payload.pageSize,
                totalItems: action.payload.totalItems,
                totalPages: action.payload.totalPages
            }
        case PRODUCT_CURRENT:
            return {
                ...state,
                product: action.payload
            }
        case PRODUCT_SET_ALLDATA:
            return {
                ...state,
                product: action.payload.product,
                resourceList: action.payload.productResourceList
            }
        case FILE_SET:
            return {
                ...state,
                file: action.payload
            };
        case PRODUCT_RESOURCE_CREATE:
            return {
                ...state,
                resourceList: [...state.resourceList, action.payload]
            }
        case PRODUCT_RESOURCE_DELETE:
            return {
                ...state,
                // I will filter all resources with a productResourceId different to productResourceId sent like a parameter(action.payload)
                resourceList: state.resourceList.filter(item => item.productResourceId !== action.payload)
            };
        case PRODUCT_RESOURCE_SET_MAIN:
            return {
                ...state,
                resourceList: state.resourceList.map(
                    item => item.productResourceId === action.payload ? { ...item, main: true } : { ...item, main: false }
                )
            };
        case PRODUCT_SET_FILTERS:
            return {
                ...state,
                productFilters: action.payload
            }
        default: return state;
    }
}