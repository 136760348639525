import React, { useEffect, useState } from 'react';
import Header from '../components/commons/Header';
import Foot from '../components/commons/Foot';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { PATH_HOME, PATH_LOGIN } from '../utils/path';
import { useDispatch, useSelector } from 'react-redux';
import { isEmail, isNull, isObjEmpty } from '../utils/functions';
import { signupUser } from '../actions/sesionAction';
import SignupForm from '../components/user/SignupForm';

const SignupPage = () => {

    const [ errors, setErrors ] = useState({});
    const dispatch = useDispatch();
    const navigate = useNavigate()

    // con state tomo el valor del store, 
    // sesion es el nombre del reducer que le doy en reducers/index.js : sesion: sesionReducer
    // logged es el nombre del parámetro del state sesionReducer en reducers/sesionReducer.js
    const logged = useSelector( state => state.sesion.logged );
    useEffect(() => {
        //console.log(Date.now());
        if (logged) {
            navigate(PATH_HOME);
        }
        // eslint-disable-next-line
    }, [logged]);

    const signUp = ({ email, password }) => {
        const errors = {};
        setErrors(errors);

        console.log("email:" + email + " / password:" + password);
        
        let ok = true;
        
        if (!isEmail((email))) {
            //errors.email = "El correo está mal ingresado";
            //ok = false;
        }
        if (isNull(password)) {
            errors.password = "Ingresa la contraseña";
            ok = false;
        }
        
        if (!isObjEmpty(errors)) {
            setErrors(errors);
        }

        if (ok) {
            console.log("OK Signup");
            dispatch(signupUser({email, password}))
                .then(response => {
                    console.log(response);
                }).catch(error => {
                    console.log(error);
                });
        }
    }

    return (
    <>
        <Header/>
        <Container className='mt-5'>
            <Row>
                <Col sm="12" md={{span:8, offset:2}} lg={{span:6, offset:3}}>
                    <Card body>
                        <h3>Crear cuenta</h3>
                        <SignupForm errors={errors} onSubmitCallback={signUp}/>
                        <div className='mt-4'>
                            <Link to={PATH_LOGIN}>¿Ya tienes una cuenta? Inicia sesión aquí</Link>
                        </div>
                    </Card>
                </Col>
            </Row>
        </Container>
        <Foot/>
    </>
    );
}
 
export default SignupPage;