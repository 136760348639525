import {thunk} from 'redux-thunk';
import reducer from './reducers'; // si le dejo así buscará el reducers/index.js
import { applyMiddleware, compose, createStore } from 'redux';
//import { configureStore } from '@reduxjs/toolkit';


const store = createStore(
    reducer ,
    
    compose(applyMiddleware(thunk), 
        // originalmente estaba así, copiado de https://github.com/zalmoxisus/redux-devtools-extension
        //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
        // Pero para que funcione en navegadores que no tienen instalada la extensión de redux poner así:
        typeof window === 'object' && typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined'
            ? window.__REDUX_DEVTOOLS_EXTENSION__()
            : f => f
    )
);
//const store = createStore(reducer)
export default store;