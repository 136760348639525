import React, { useEffect, useState } from 'react';
import UserDataForm from '../components/user/UserDataForm';
import Header from '../components/commons/Header';
import Foot from '../components/commons/Foot';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { isNull, isObjEmpty } from '../utils/functions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateUser } from '../actions/sesionAction';
import Swal from 'sweetalert2';
import { PATH_HOME } from '../utils/path';

const UserPage = () => {

    const logged = useSelector( state => state.sesion.logged );
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [ errors, setErrors ] = useState({});

    useEffect(() => {
        //console.log(Date.now());
        if (!logged) {
            navigate(PATH_HOME); // Here only with logged users
        }
        // eslint-disable-next-line
    }, [logged]);

    const updateData = (userData) => {
        const errors = {};
        setErrors(errors);
        console.log("UserPage userData:");
        console.log(userData);

        let warning = "";
        let ok = true;

        if (isNull(userData.firstName)) 
            warning = " - El nombre está vacío\n";
        else if (userData.firstName.length > 20 ) {
            errors.firstName = "El nombre no debe tener más de 20 carateres, tiene " + userData.firstName.length;
            ok = false;
        }

        if (isNull(userData.lastName))
            warning += " - El apellido está vacío\n";
        else if (userData.lastName.length > 20 ) {
            errors.lastName = "El apellido no debe tener más de 20 caracteres, tiene " + userData.lastName.length;
            ok = false;
        }

        if (isNull(userData.phone))
            warning += " - El teléfono está vacío\n";
        //else errors.phone = "Ingresa el teléfono";

        console.log("UserPage.updateData errors:", errors);
        console.log("UserPage.updateData warning: ", warning);

        if (!isObjEmpty(errors)) {
            setErrors(errors);
        }

        if (ok) {
            
            console.log("UserPage.updateData userData:", userData);

            if (warning.length > 0) {
                warning += "\n¿Deseas continuar?"
                Swal.fire({ 
                    icon:"question", title:"Alerta", text: warning, confirmButtonText: "Actualizar", 
                    showCancelButton: true, cancelButtonText: "No"
                }).then(async (result) => {
                    if (result.value) {
                        callUpdate(userData);
                    } else {
                        return;
                    }
                });
            } else {
                callUpdate(userData);
            }
            console.log("después de Alert");
            
        }
    }

    const callUpdate = (userData) => {
        dispatch(updateUser(userData))
            .then(response => {
                Swal.fire({icon: "success", title: "Actualizado", text: "Los datos se han actualizado", toast: true});
                console.log("UserPage.callUpdate response: ", response);
            }).catch(error => {
                console.log("UserPage.callUpdate error: ", error);
            });
    } 

    return (
        <>
            <Header/>
            <Container className='mt-5'>
                <Row>
                    <Col sm="12" md={{span:8, offset:2}} lg={{span:6, offset:3}}>
                        <Card body>
                            <h3>Mis Datos</h3>
                            <UserDataForm errors={errors} onSubmitCallback={updateData}/>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Foot/>
        </>
    );
}
 
export default UserPage;