import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';

const LoginForm = ({errors, onSubmitCallback}) => {
    const [email, setEmail] = useState(""); //useState("yo@h.c");
    const [password, setPassword] = useState(""); //useState("pass");

    const submitForm = (e) => {
        e.preventDefault();
        onSubmitCallback({email, password});
    }
    return (
    <>
        <Form onSubmit={submitForm}>
            <Form.Group control="email">
                <Form.Label>Correo</Form.Label>
                <Form.Control type='email' value={email} 
                    onChange={ e => setEmail(e.target.value) }
                    placeholder='Ingresa el correo'
                    isInvalid={errors.email}
                />
                <Form.Control.Feedback type='invalid'>{errors.email}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group control="password">
                <Form.Label>Contraseña</Form.Label>
                <Form.Control type='password' value={password} 
                    onChange={ e => setPassword(e.target.value) }
                    placeholder='Ingresa la contraseña'
                    isInvalid={errors.password}
                />
                <Form.Control.Feedback type='invalid'>{errors.password}</Form.Control.Feedback>
            </Form.Group>
            <Button variant='primary' type='submit' className='mt-4'>Iniciar sesión</Button>
        </Form>
    </>
    );
}
 
export default LoginForm;