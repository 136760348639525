export const SET_SHOW_MODAL = "set show modal";
export const SET_POPUP = "set popUp";
export const SET_SOLO_LECTURA = "set solo lectura";

export const NEVER = "(Ninguno)";
export const SELECT = "(Selecciona)";
export const ALL = "(Todos)";
export const FROM = "(Desde)";
export const UNTIL = "(Hasta)";

export const SET_USER_SESSION = "setear o asignar una estructura {sub, exp} que ha iniciado sesión";
export const SET_SESSION = "setear o asignar los datos de usuario que ha iniciado sesión";
export const SET_TRY_LOGIN = "false si no se ha intentado el inicio de sesión, true sí se intentó iniciar sesión.";
export const SET_OPENED_DASHBOARD = "true si ya se abrió alguna vez el tablero o página de estudiante o facilitador, false si todavía no se abre";
export const SET_SHOW_MENU = "true o false, para mostrar/ocultar el menú en alguna vista. Se lo modifica con botón de menú en el header";

export const FILE_SET = "set/save file metadata";

export const PRODUCT_LOAD = "load products";
export const PRODUCT_CURRENT = "current product";
export const PRODUCT_SET_ALLDATA = "get the product and the productResourceList";
export const PRODUCT_RESOURCE_CREATE = "use this for add a product resource to resourceList";
export const PRODUCT_RESOURCE_DELETE = "use this for remove a product resource from resourceList";
export const PRODUCT_RESOURCE_SET_MAIN = "Set only one resource.main = true from resourceList";
export const PRODUCT_SET_FILTERS = "Set filter to find products";