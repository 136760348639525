import Axios from 'axios';
import Swal from 'sweetalert2';
// eslint-disable-next-line
import { isNull, tieneError, getMensajeError } from '../utils/functions';

// Para consumir WS uso la palabra async que me permitirá luego usar la palabra await.
// Debido a que los eventos de javascript son asíncronos. Y a veces necesito que algunas líneas se ejecuten en orden
export const envioCorreo = async (destino, asunto, mensaje) => {
    console.log("correo.envioCorreo: dto:");
    
    try {
        let dto = {
            destino: destino,
            asunto: asunto,
            mensaje: mensaje
        }
        console.log(dto);
        // la palabra await fuerza a que se espere que se ejecute esta línea, ya que es indispensable 
        // antes de lanzar el dispatch
        const resultado = await Axios.post("/correo", dto);
        //console.log(resultado);
        return resultado.data === "ok";
    } catch (error) {
        // Swal es para los alerts, o miniventanas para mensajes en la web
        Swal.fire({icon: "error", title: "Error", text: "No se envió el correo", toast: true});
        console.log(error);
        return false;
    }
}