import { SET_OPENED_DASHBOARD, SET_TRY_LOGIN, SET_SESSION, SET_USER_SESSION } from '../const/actionType';

const initialState = {
    logged: false,
    tryLogin: false, // false si no se ha intentado el inicio de sesión, true sí se intentó iniciar sesión.
    openedDashboard: false, // true si ya se abrió alguna vez el tablero o página de estudiante o facilitador, false si todavía no se abre. Esto porque se debe abrir al inicio en vez del la home page
    // usuario tendrá la estructura del entity Usuario
    user: {
        fullName: "No iniciado",
        shortName: "No iniciado"
    },
    // con llaves así  {} defino que es un objeto vacío, userSession tiene la estructura {sub, exp} sub es el user(correo), y exp es el tiempo de expiración
    userSession: {} 
}

export default function sesionReducer(state = initialState, action) {
    switch(action.type) {
        case SET_SESSION: 
            return {
                ...state,
                logged: action.payload.logged,
                tryLogin: action.payload.tryLogin,
                userSession: action.payload.userSession
            }
        case SET_USER_SESSION: 
            return {
                ...state,
                user: action.payload
            }
        case SET_TRY_LOGIN:
            return {
                ...state,
                tryLogin: action.payload
            }
        case SET_OPENED_DASHBOARD:
            return {
                ...state,
                openedDashboard: action.payload
            }
        default: return state;
    }
}