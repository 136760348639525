import React, { useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { PATH_PRODUCT } from '../../utils/path';

const ProductHomeCard = ({product}) => {

  useEffect(() => {
    console.log("ProductHomeCard.useEffect.[] product:", product);
    // eslint-disable-next-line
  }, []);

return (<>
    <Card 
      style={{ textDecoration: 'none' }} // By the NavLink all the texts inside this have underline, this is the unique way to avoid it
      className='galery-card shadow p-3 mb-5 bg-white rounded'
      as={NavLink} to={PATH_PRODUCT + "/" + product.product.productId}
    >
      <Card.Img variant="top"
        //style={{ height: '13rem' }}
        className='galery-img'
        src={product.file? product.file.uriOpen: "/home_icon.png"} 
      />
      <Card.Body>
        <h5 className='galery-product-name'>{product.product.name? product.product.name: ""}</h5>
        <Card.Text></Card.Text>
        <Row>
          <Col className='pr-0'>
            <span className="icon ">
              <i className="fa-solid fa-dollar-sign" /><text style={{fontSize: '1.1rem', fontWeight: '600'}}>{" " + product.product.publicPrice}</text>
            </span>
          </Col>
        </Row>
        <Row>  
          {/* product.product.landArea &&*/}
              <Col className='pr-0'>
                <span className="icon is-small">
                  <i className="fa-solid fa-expand" /><small></small><text>{" " + product.product.landArea}</text>
                </span>
              </Col>
          
          {/* product.product.livableArea &&*/}
              <Col hidden={undefined === product.product.livableArea} className='pr-0'>
                <span className="icon is-small">
                  <i className="fa-solid fa-house" /><small></small><text>{" " + product.product.livableArea}</text>
                </span>
              </Col>
          
        </Row>
        
        
      </Card.Body>
    </Card>
</>);
}
 
export default ProductHomeCard;