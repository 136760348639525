import React, { useEffect } from 'react';
import Header from '../../components/commons/Header';
import Foot from '../../components/commons/Foot';
import ProductCreateForm from '../../components/product/ProductCreateForm';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { productFindById } from '../../actions/productAction';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { PATH_HOME, PATH_MY_PRODUCTS } from '../../utils/path';

const ProductUpdatePage = () => {

    const { id } = useParams();
    const dispatch = useDispatch();

    const logged = useSelector(state => state.sesion.logged);
    const navigate = useNavigate();

    useEffect(() => {
        if (!logged) {
            navigate(PATH_HOME); // Here only with logged users
        }
    // eslint-disable-next-line
    }, [logged]);

    useEffect(() => {
        if (id) {
            dispatch(productFindById(id));
        }
        // eslint-disable-next-line
    }, []);

    return (
    <>
        <Header/>
        <Container className='mt-5'>
            <Row>
                <Col sm="12" md={{span:8, offset:2}} lg={{span:6, offset:3}}>
                    <Card body>
                        <Row>
                            <Col>
                                <h3>Actualizar Datos</h3>
                            </Col>
                            <Col className="d-flex justify-content-end">
                                <Button as={NavLink} to={PATH_MY_PRODUCTS}>Regresar</Button>
                            </Col>
                        </Row>
                        <ProductCreateForm />
                    </Card>
                </Col>
            </Row>
        </Container>
        
        <Foot/>
    </>
        
    );
}
 
export default ProductUpdatePage;