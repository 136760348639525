import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import store from '../store';
import { SET_TRY_LOGIN, SET_SESSION, SET_USER_SESSION, SELECT, ALL, NEVER } from '../const/actionType';
import { logoutUsuario } from '../actions/sesionAction';

export const isNull = (value) => {
    if (value === null) { return true; }
    if (value === undefined) { return true; }
    if (value === 0 || value === "0" || value === "0.0" ) return false;
    if (value === false) return false;
    if (!value) { return true; }
    if (isNaN(value)){ // pongo esto porque me da error cuando en el input le pongo type="number"
        if ((value).trim() === "") { return true; }
        if ((value).trim().toLowerCase() === "null") { return true; }
    }
    if (value === "") { return true; }
    return false;
}

export const noSelected = (value) => {
    if (isNull(value)) return true;
    // eslint-disable-next-line
    if (value == SELECT || value == ALL || value == NEVER) return true;
    if (value === -1 || value === "-1" ) return true;
    return false;
}

export const isSelected = (value) => { return !noSelected(value); }

export const isEmail = (correo) => {
    // eslint-disable-next-line
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if(!correo || regex.test(correo) === false){
        return false;
    }
    return true;
}

// me sirve para saber si un estado/variable que es compuesto ejem: persona = { nombre: 'rafa', edad: 12 }
// si esa variable tiene datos o si está vacío como : persona = {}
export const isObjEmpty = (objeto) => {
    return Object.keys(objeto).length === 0; //&& objeto.constructos === Object;
}

// para cuando se consuma un WS
export const tieneError = (resultado) => {
    //if (!isNull(resultado.data.erroresValidacion)) return true;
    if (resultado.data.erroresValidacion) return true;
    if (resultado.data.errorDetail) return true;
    if (resultado.data.mensajeError) return true;
    return false;
}

// para cuando se consuma un WS
export const getMensajeError = (resultado) => {
    if (resultado.data.erroresValidacion) return JSON.stringify(resultado.data.erroresValidacion);
    if (resultado.data.errorDetail) return "" + resultado.data.errorDetail;
    if (resultado.data.mensajeError) return "" + resultado.data.mensajeError;
    return null;
}

export const setAuthToken = (token) => {
    // Asignar o Predefinir el header Authorization con el valor del token a todas las peticiones WS
    // Para no tener la necesidad de ponerlo en cada petición
    // zzzz fix the backend security and uncomment theese lines
    //if (token) axios.defaults.headers.common["Authorization"] = token;
    //else delete axios.defaults.headers.common["Authorization"];
}

export const checkAuthToken = () => {
    // se llama al iniciar la aplicación en el cliente, y lo que se quiere es verificar si hay una sesión iniciada y 
    // poner los datos en el reducer/store

    console.log("functions.checkAuthToken. localStorage.token:");
    console.log(localStorage.token);

    // token es el campo que se asignó en sesionAction así: localStorage.setItem("token", authorization);
    if (localStorage.token) {
        setAuthToken(localStorage.token);
        // decoded tiene la estructura {sub, exp} sub es el subject/user(es el correo en este caso)
        // y exp es la fecha en segundos del tiempo de expiración
        const decoded = jwtDecode(localStorage.token);
        store.dispatch({
            type: SET_SESSION,
            payload: { userSession: decoded, logged: true, tryLogin: true }
        });

        console.log("functions.checkAuthToken. decoded:");
        console.log(decoded);

        // obtener los datos del entity user que ha iniciado sesión.
        axios.get('/user/email/' + decoded.sub , {
            headers: {'Accept': 'application/json', 'Content-type': 'application/json'}
        }).then( userResponse => {
            console.log("functions.checkAuthToken. axios.get /user/email userResponse:");
            console.log(userResponse);
            console.log(decoded);
            store.dispatch({
                type: SET_USER_SESSION,
                payload: userResponse.data 
            });
        }).catch ( error => {
            //reject(error);
            console.log("functions.checkAuthToken. axios.get /user/email error:");
            console.log(error);
        });

        const nowDate = Math.floor(Date.now() / 1000); // nowDate has the date in seconds
        if (decoded.exp < nowDate) {
            store.dispatch(logoutUsuario());
            window.location.href = "/";
        }
    } else {
        store.dispatch({
            type: SET_TRY_LOGIN,
            payload: true 
        });
    }
}

// para cuando se consuma un WS
export const getErrorMessage = (resultado) => {
    if (resultado.data.erroresValidacion) return JSON.stringify(resultado.data.erroresValidacion);
    if (resultado.data.errorDetail) return "" + resultado.data.errorDetail;
    if (resultado.data.mensajeError) return "" + resultado.data.mensajeError;
    return null;
}

export const getYesNoList = (first) => {
    let tmp = [ 
        { value: true, label: "Sí" }, 
        { value: false, label: "No" } ];
    if (!isNull(first)) return [{ value: -1, label: first }, ...tmp];
    else return tmp;
}

export const getEntireNumbersList = (first, from, to) => {
    let tmp = [];
    for ( let i = from; i <= to; i++ )
        tmp = [ ...tmp, i ];
    if (!isNull(first)) return [first, ...tmp];
    else return tmp;
}

export const getAreasList = (first) => {
    let tmp = [40, 80, 120, 180, 240, 320, 400, 500, 600, 1000, 2000];
    if (isNull(first)) return tmp;
    else return [first, ...tmp];
}

export const getPricesList = (first) => {
    let tmp = ['50.000', '75.000', '100.000', '125.000', '150.000', '175.000', '200.000', '250.000', '300.000', '400.000', '500.000', '750.000'];
    if (isNull(first)) return tmp;
    else return [first, ...tmp];
}