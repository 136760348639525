import React, { useEffect, useState } from 'react';
import Header from '../components/commons/Header';
import Foot from '../components/commons/Foot';
import LoginForm from '../components/user/LoginForm';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { PATH_HOME, PATH_SIGNUP } from '../utils/path';
import { useDispatch, useSelector } from 'react-redux';
import { isEmail, isNull, isObjEmpty } from '../utils/functions';
import { loginUser } from '../actions/sesionAction';

const LoginPage = () => {

    const [ errors, setErrors ] = useState({});
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // con state tomo el valor del store, 
    // sesion es el nombre del reducer que le doy en reducers/index.js : sesion: sesionReducer
    // logged es el nombre del parámetro del state sesionReducer en reducers/sesionReducer.js
    const logged = useSelector( state => state.sesion.logged );
    
    useEffect(() => {
        //console.log(Date.now());
        if (logged) {
            navigate(PATH_HOME);
        }
        // eslint-disable-next-line
    }, [logged]);

    const login = ({ email, password }) => {
        const errors = {};
        setErrors(errors);

        console.log("email:" + email + " / password:" + password);
        
        let ok = true;
        
        if (!isEmail((email))) {
            //errors.email = "El correo está mal ingresado";
            //ok = false;
        }
        if (isNull(password)) {
            errors.password = "Ingresa la contraseña";
            ok = false;
        }
        
        if (!isObjEmpty(errors)) {
            setErrors(errors);
        }

        if (ok) {
            console.log("OK Login");
            dispatch(loginUser({email, password}))
                .then(response => {
                    console.log(response);
                }).catch(error => {
                    console.log(error);
                });
        }
    }

    return (
    <>
        <Header/>
        <Container className='mt-5'>
            <Row>
                <Col sm="12" md={{span:8, offset:2}} lg={{span:6, offset:3}}>
                    <Card body>
                        <h3>Iniciar Sesión</h3>
                        <LoginForm errors={errors} onSubmitCallback={login}/>
                        <div className='mt-4'>
                            <Link to={PATH_SIGNUP}>¿No tienes una cuenta? Regístrate aquí</Link>
                        </div>
                    </Card>
                </Col>
            </Row>
        </Container>
        <Foot/>
    </>
    );
}
 
export default LoginPage;